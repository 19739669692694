<template>
  <main role="main">
    <section class="section section-page-header">
      <div class="container m-container-only-s">
        <h1>{{ $t("upgrade-seat.info_title") }}</h1>
      </div>
    </section>

    <section class="section section-localnav plane-floor-chooser">
      <nav class="tabnav">
        <ul class="tabnav-items">
          <li class="tabnav-offset-item"><div></div></li>
          <li
            class="tabnav-item tabnav-first-item"
            :class="{ active: nav === 'top' }"
          >
            <em></em>
            <a
              href="#"
              @click.prevent="nav = 'top'"
              class="tabnav-link"
              data-show="plane-b747-400-er-v3-top"
              >Верхняя палуба</a
            >
          </li>
          <li class="tabnav-item" :class="{ active: nav === 'bottom' }">
            <em></em>
            <a
              href="#"
              @click.prevent="nav = 'bottom'"
              class="tabnav-link"
              data-show="plane-b747-400-er-v3-bottom"
              >Нижняя палуба</a
            >
          </li>
          <li class="tabnav-offset-item"><div></div></li>
        </ul>
      </nav>
    </section>
    <section
      class="section section-default section-seats"
      v-show="nav === 'bottom'"
    >
      <div class="container m-container-only-s">
        <i class="help-info" @click="infoPopup = !infoPopup"></i>
        <div class="columns plane-b747-400-er-v3-bottom" id="seat-map">
          <div class="map-column">
            <div data-floor="plane-b747-400-er-v3-bottom" class="seats-map">
              <div class="head-columns">
                <div class="head-column rotate-left">
                  <div
                    class="head-row align-center"
                    v-for="(row, index) in modifySeats.seats.filter(
                      item => item.row <= 4
                    )"
                    :key="index"
                    data-row="10"
                  >
                    <div
                      class="seat"
                      data-seat="A"
                      :key="idx"
                      @click="checkUpgradeSeat(seat, row.seats[0])"
                      :class="[
                        seat.seatAvailability === 0 ||
                        seat.nameHash ||
                        seat.disabled
                          ? 'unavailable'
                          : '',
                        seat.class,
                        seat.seatNumber === parentSelected ? 'selected' : '',
                        user.seat === seat.seatNumber ? 'active' : ''
                      ]"
                      v-for="(seat, idx) in row.seats[0]"
                    >
                      <i></i>
                      <div class="label">{{ seat.seatNumber }}</div>
                    </div>
                  </div>
                </div>
                <div class="head-column middle-column">
                  <div
                    class="head-row align-center"
                    data-row="17"
                    v-for="(row, index) in modifySeats.seats.filter(
                      item => item.row <= 4
                    )"
                    :key="index"
                  >
                    <div
                      class="seat"
                      data-seat="A"
                      :key="idx"
                      @click="checkUpgradeSeat(seat, row.seats[1])"
                      :class="[
                        seat.seatAvailability === 0 ||
                        seat.nameHash ||
                        seat.disabled
                          ? 'unavailable'
                          : '',
                        seat.class,
                        seat.seatNumber === parentSelected ? 'selected' : '',
                        user.seat === seat.seatNumber ? 'active' : ''
                      ]"
                      v-for="(seat, idx) in row.seats[1]"
                    >
                      <i></i>
                      <div class="label">{{ seat.seatNumber }}</div>
                    </div>
                  </div>
                </div>
                <div class="head-column rotate-right">
                  <div
                    class="head-row align-center"
                    data-row="10"
                    v-for="(row, index) in modifySeats.seats.filter(
                      item => item.row <= 4
                    )"
                    :key="index"
                  >
                    <div
                      class="seat"
                      data-seat="A"
                      :key="idx"
                      @click="checkUpgradeSeat(seat, row.seats[2])"
                      :class="[
                        seat.seatAvailability === 0 ||
                        seat.nameHash ||
                        seat.disabled
                          ? 'unavailable'
                          : '',
                        seat.class,
                        seat.seatNumber === parentSelected ? 'selected' : '',
                        user.seat === seat.seatNumber ? 'active' : ''
                      ]"
                      v-for="(seat, idx) in row.seats[2]"
                    >
                      <i></i>
                      <div class="label">{{ seat.seatNumber }}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="seats-row "
                :class="[row.class, { 'after-head': index === 0 }]"
                :key="index"
                v-for="(row, index) in modifySeats.seats.filter(
                  item => item.row >= 20
                )"
              >
                <div
                  class="seats-column"
                  :key="idx"
                  v-for="(seats, idx) in row.seats"
                  :class="[
                    seats[0] &&
                      modifySeats.class.find(item => item.row === row.row) &&
                      modifySeats.class
                        .find(item => item.row === row.row)
                        .seats.find(item => item.group === seats[0].group)
                        .class,
                    {
                      'four-seats':
                        seats.length === 0 &&
                        [21, 22, 34, 53, 54, 55, 56, 57, 58, 59].indexOf(
                          row.row
                        ) !== -1 &&
                        idx === 1
                    }
                  ]"
                >
                  <div
                    :key="ix"
                    v-for="(seat, ix) in seats"
                    class="seat"
                    @click="checkUpgradeSeat(seat, seats)"
                    :class="[
                      seat.seatAvailability === 0 ||
                      seat.nameHash ||
                      seat.disabled
                        ? 'unavailable'
                        : '',
                      seat.class,
                      seat.seatNumber === parentSelected ? 'selected' : '',
                      user.seat === seat.seatNumber ? 'active' : ''
                    ]"
                  >
                    <i></i>
                    <b></b>
                    <div class="label">{{ seat.seatNumber }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <info
            :selected-column="parentSelectedColumn"
            :selected="parentSelected"
            @selected="parentSelected = false"
            :type="type"
            :info="infoPopup"
            @closeInfo="infoPopup = false"
            @update="$emit('update', $event)"
          ></info>
        </div>
      </div>
    </section>
    <section
      class="section section-default section-seats"
      v-show="nav === 'top'"
    >
      <div class="container m-container-only-s">
        <i class="help-info" @click="infoPopup = !infoPopup"></i>
        <div class="columns plane-b747-400-er-v3-top">
          <div class="map-column">
            <div class="seats-map">
              <div
                class="seats-row "
                :class="row.class"
                :key="index"
                v-for="(row, index) in modifySeats.seats.filter(
                  item => item.row >= 5 && item.row <= 11
                )"
              >
                <div
                  class="seats-column"
                  :key="idx"
                  v-for="(seats, idx) in row.seats"
                  :class="[
                    seats[0] &&
                      modifySeats.class.find(item => item.row === row.row) &&
                      modifySeats.class
                        .find(item => item.row === row.row)
                        .seats.find(item => item.group === seats[0].group)
                        .class,
                    {
                      'four-seats':
                        seats.length === 0 &&
                        [29, 34, 35].indexOf(row.row) !== -1
                    }
                  ]"
                >
                  <div
                    :key="ix"
                    v-for="(seat, ix) in seats"
                    class="seat"
                    @click="checkUpgradeSeat(seat, seats)"
                    :class="[
                      seat.seatAvailability === 0 ||
                      seat.nameHash ||
                      seat.disabled
                        ? 'unavailable'
                        : '',
                      seat.class,
                      seat.seatNumber === parentSelected ? 'selected' : '',
                      user.seat === seat.seatNumber ? 'active' : ''
                    ]"
                  >
                    <i></i>
                    <b></b>
                    <div class="label">{{ seat.seatNumber }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <info
            :selected-column="parentSelectedColumn"
            :selected="parentSelected"
            @selected="parentSelected = false"
            :type="type"
            :info="infoPopup"
            @closeInfo="infoPopup = false"
            @update="$emit('update', $event)"
          ></info>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import Info from "@/components/planes/Info";
import planesReplace from "@/mixins/planesReplace";
import { mapActions, mapState } from "vuex";

export default {
  components: {
    Info
  },
  props: ["change", "seats", "type"],
  mixins: [planesReplace],
  name: "B744C",
  data: () => {
    return {
      nav: "bottom",
      spacesConstant: { 30: " pass", 43: " pass", 55: " small-pass" },
      rows: {},
      offsetTop: [36, 37, 38, 39, 40, 41, 42, 43],
      offsetBottom: [],
      parentSelectedColumn: [],
      parentSelected: ""
    };
  },
  watch: {
    parentSelected(value) {
      if (value) {
        document.documentElement.classList.add("seat-map-popup-opened");
      } else {
        document.documentElement.classList.remove("seat-map-popup-opened");
      }
    }
  },
  beforeDestroy() {
    document.documentElement.classList.remove("seat-map-layout");

    document.documentElement.classList.remove("seat-map-popup-opened");
  },
  beforeCreate() {
    document.documentElement.classList.add("seat-map-layout");
  },
  computed: {
    ...mapState("user", {
      user: state => state.user
    }),
    ...mapState("order", {
      layout: state => state.layout
    }),
    getLayoutUpgrade() {
      return this.layout.find(item => item.category === "upgradePlace");
    },
    modifySeats: {
      get() {
        let rowObject = {};
        if (this.$store.state.flight.reload) {
          rowObject = {};
        }

        const newArray = [];
        const ms = this.seats.map(item => {
          let className = this.replaces[item.class];
          if (!item.features) {
            item.features = [];
          }
          item.features.sort(function(a, b) {
            if (a < b) {
              return 1;
            }
            if (a > b) {
              return -1;
            }
            return 0;
          });
          for (let i = 0; i < item.features.length; i++) {
            className = className + "" + this.features[item.features[i]];
          }
          return {
            row: parseInt(item.seatNumber),
            seat: {
              ...item,
              class: className
            }
          };
        });
        let clases = [];
        for (let i = 0; i < this.seats.length; i++) {
          let row = parseInt(this.seats[i].seatNumber);
          if (rowObject.row !== row) {
            rowObject = {};
            rowObject.row = row;
            rowObject.class =
              this.seats[i].class === "c" ? "business-row" : "econom-row";
            if (
              rowObject.class === "econom-row" &&
              newArray[newArray.length - 1] &&
              newArray[newArray.length - 1].class === "business-row"
            ) {
              rowObject.class = "econom-row ";
            }
            if (this.spacesConstant[row]) {
              rowObject.class += this.spacesConstant[row];
            }
            rowObject.seats = this.chunkArray(
              ms
                .filter(it => it.row === row)
                .map(itm => {
                  return itm.seat;
                }),
              row
            );
            newArray.push(rowObject);
            clases.push({
              row: row,
              seats: this.setClases(rowObject.seats, row)
            });
          }
        }
        console.log(newArray);
        return { seats: newArray, class: clases };
      }
    }
  },
  methods: {
    ...mapActions("order", {
      cancelOrder: "cancelOrder"
    }),
    checkUpgradeSeat(seat, seats) {
      if (
        seat.seatAvailability === 0 ||
        seat.nameHash ||
        seat.seatNumber === this.user.seat ||
        seat.disabled
      ) {
        return false;
      }
      this.cancelOrder({ id: this.getLayoutUpgrade.id }).then(() => {
        this.parentSelectedColumn = seats;
        this.parentSelected = seat.seatNumber;
      });
    },
    chunkArray(array, row) {
      const newArray = [];
      if (row >= 11 && row <= 5) {
        for (let i = 1; i <= 3; i++) {
          if (
            array.filter(item => item.group === i) &&
            array.find(item => item.group === i)
          ) {
            newArray.push(array.filter(item => item.group === i));
          }
        }
      } else if (row <= 11 && row >= 5) {
        newArray.push(array.filter(item => item.group === 1));
        newArray.push(array.filter(item => item.group === 3));
      } else {
        for (let i = 1; i <= 3; i++) {
          newArray.push(array.filter(item => item.group === i));
        }
      }

      return newArray;
    },
    setClases(array, row) {
      let newArray = [];
      let countLength = 2;
      for (let i = 0; i <= countLength; i++) {
        let newClass = "";
        if (array[i]) {
          let seats = array[i];

          if (seats.length === 2) {
            newClass += " two-seats";
          }
          if (seats.length === 3 && row >= 10 && i === 1) {
            newClass += " middle-column";
          }
          if (seats.length === 4 || (seats.length === 0 && i === 1)) {
            newClass += " four-seats";
          }
          if (seats.length === 4 && this.offsetBottom.find(it => it === row)) {
            newClass += " offset-bottom";
          }
          if (seats.length === 4 && this.offsetTop.find(it => it === row)) {
            newClass += " offset-top";
          }

          // if (row === 9) {
          //   if (seats.length === 2) {
          //     if (i === 0) {
          //       newClass += " align-right";
          //     }
          //   }
          // }
          //
          if (row >= 67) {
            if (seats.length === 2) {
              if (i === 0) {
                newClass += " align-right";
              }
              if (i === 2) {
                newClass += " align-left";
              }
            }
          }
          if (row >= 21 && row <= 22) {
            if (seats.length === 2) {
              if (i === 2) {
                newClass += " align-right rotate-right-3";
              }
            }
          }
        }

        newArray.push({ row: row, group: i + 1, class: newClass });
      }
      return newArray;
    }
  }
};
</script>

<style scoped>
.off {
  opacity: 0.4;
}
</style>
