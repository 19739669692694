var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',{attrs:{"role":"main"}},[_c('section',{staticClass:"section section-page-header"},[_c('div',{staticClass:"container m-container-only-s"},[_c('h1',[_vm._v(_vm._s(_vm.$t("upgrade-seat.info_title")))])])]),_c('section',{staticClass:"section section-localnav plane-floor-chooser"},[_c('nav',{staticClass:"tabnav"},[_c('ul',{staticClass:"tabnav-items"},[_vm._m(0),_c('li',{staticClass:"tabnav-item tabnav-first-item",class:{ active: _vm.nav === 'top' }},[_c('em'),_c('a',{staticClass:"tabnav-link",attrs:{"href":"#","data-show":"plane-b747-400-er-v3-top"},on:{"click":function($event){$event.preventDefault();_vm.nav = 'top'}}},[_vm._v("Верхняя палуба")])]),_c('li',{staticClass:"tabnav-item",class:{ active: _vm.nav === 'bottom' }},[_c('em'),_c('a',{staticClass:"tabnav-link",attrs:{"href":"#","data-show":"plane-b747-400-er-v3-bottom"},on:{"click":function($event){$event.preventDefault();_vm.nav = 'bottom'}}},[_vm._v("Нижняя палуба")])]),_vm._m(1)])])]),_c('section',{directives:[{name:"show",rawName:"v-show",value:(_vm.nav === 'bottom'),expression:"nav === 'bottom'"}],staticClass:"section section-default section-seats"},[_c('div',{staticClass:"container m-container-only-s"},[_c('i',{staticClass:"help-info",on:{"click":function($event){_vm.infoPopup = !_vm.infoPopup}}}),_c('div',{staticClass:"columns plane-b747-400-er-v3-bottom",attrs:{"id":"seat-map"}},[_c('div',{staticClass:"map-column"},[_c('div',{staticClass:"seats-map",attrs:{"data-floor":"plane-b747-400-er-v3-bottom"}},[_c('div',{staticClass:"head-columns"},[_c('div',{staticClass:"head-column rotate-left"},_vm._l((_vm.modifySeats.seats.filter(
                    function (item) { return item.row <= 4; }
                  )),function(row,index){return _c('div',{key:index,staticClass:"head-row align-center",attrs:{"data-row":"10"}},_vm._l((row.seats[0]),function(seat,idx){return _c('div',{key:idx,staticClass:"seat",class:[
                      seat.seatAvailability === 0 ||
                      seat.nameHash ||
                      seat.disabled
                        ? 'unavailable'
                        : '',
                      seat.class,
                      seat.seatNumber === _vm.parentSelected ? 'selected' : '',
                      _vm.user.seat === seat.seatNumber ? 'active' : ''
                    ],attrs:{"data-seat":"A"},on:{"click":function($event){return _vm.checkUpgradeSeat(seat, row.seats[0])}}},[_c('i'),_c('div',{staticClass:"label"},[_vm._v(_vm._s(seat.seatNumber))])])}),0)}),0),_c('div',{staticClass:"head-column middle-column"},_vm._l((_vm.modifySeats.seats.filter(
                    function (item) { return item.row <= 4; }
                  )),function(row,index){return _c('div',{key:index,staticClass:"head-row align-center",attrs:{"data-row":"17"}},_vm._l((row.seats[1]),function(seat,idx){return _c('div',{key:idx,staticClass:"seat",class:[
                      seat.seatAvailability === 0 ||
                      seat.nameHash ||
                      seat.disabled
                        ? 'unavailable'
                        : '',
                      seat.class,
                      seat.seatNumber === _vm.parentSelected ? 'selected' : '',
                      _vm.user.seat === seat.seatNumber ? 'active' : ''
                    ],attrs:{"data-seat":"A"},on:{"click":function($event){return _vm.checkUpgradeSeat(seat, row.seats[1])}}},[_c('i'),_c('div',{staticClass:"label"},[_vm._v(_vm._s(seat.seatNumber))])])}),0)}),0),_c('div',{staticClass:"head-column rotate-right"},_vm._l((_vm.modifySeats.seats.filter(
                    function (item) { return item.row <= 4; }
                  )),function(row,index){return _c('div',{key:index,staticClass:"head-row align-center",attrs:{"data-row":"10"}},_vm._l((row.seats[2]),function(seat,idx){return _c('div',{key:idx,staticClass:"seat",class:[
                      seat.seatAvailability === 0 ||
                      seat.nameHash ||
                      seat.disabled
                        ? 'unavailable'
                        : '',
                      seat.class,
                      seat.seatNumber === _vm.parentSelected ? 'selected' : '',
                      _vm.user.seat === seat.seatNumber ? 'active' : ''
                    ],attrs:{"data-seat":"A"},on:{"click":function($event){return _vm.checkUpgradeSeat(seat, row.seats[2])}}},[_c('i'),_c('div',{staticClass:"label"},[_vm._v(_vm._s(seat.seatNumber))])])}),0)}),0)]),_vm._l((_vm.modifySeats.seats.filter(
                function (item) { return item.row >= 20; }
              )),function(row,index){return _c('div',{key:index,staticClass:"seats-row ",class:[row.class, { 'after-head': index === 0 }]},_vm._l((row.seats),function(seats,idx){return _c('div',{key:idx,staticClass:"seats-column",class:[
                  seats[0] &&
                    _vm.modifySeats.class.find(function (item) { return item.row === row.row; }) &&
                    _vm.modifySeats.class
                      .find(function (item) { return item.row === row.row; })
                      .seats.find(function (item) { return item.group === seats[0].group; })
                      .class,
                  {
                    'four-seats':
                      seats.length === 0 &&
                      [21, 22, 34, 53, 54, 55, 56, 57, 58, 59].indexOf(
                        row.row
                      ) !== -1 &&
                      idx === 1
                  }
                ]},_vm._l((seats),function(seat,ix){return _c('div',{key:ix,staticClass:"seat",class:[
                    seat.seatAvailability === 0 ||
                    seat.nameHash ||
                    seat.disabled
                      ? 'unavailable'
                      : '',
                    seat.class,
                    seat.seatNumber === _vm.parentSelected ? 'selected' : '',
                    _vm.user.seat === seat.seatNumber ? 'active' : ''
                  ],on:{"click":function($event){return _vm.checkUpgradeSeat(seat, seats)}}},[_c('i'),_c('b'),_c('div',{staticClass:"label"},[_vm._v(_vm._s(seat.seatNumber))])])}),0)}),0)})],2)]),_c('info',{attrs:{"selected-column":_vm.parentSelectedColumn,"selected":_vm.parentSelected,"type":_vm.type,"info":_vm.infoPopup},on:{"selected":function($event){_vm.parentSelected = false},"closeInfo":function($event){_vm.infoPopup = false},"update":function($event){return _vm.$emit('update', $event)}}})],1)])]),_c('section',{directives:[{name:"show",rawName:"v-show",value:(_vm.nav === 'top'),expression:"nav === 'top'"}],staticClass:"section section-default section-seats"},[_c('div',{staticClass:"container m-container-only-s"},[_c('i',{staticClass:"help-info",on:{"click":function($event){_vm.infoPopup = !_vm.infoPopup}}}),_c('div',{staticClass:"columns plane-b747-400-er-v3-top"},[_c('div',{staticClass:"map-column"},[_c('div',{staticClass:"seats-map"},_vm._l((_vm.modifySeats.seats.filter(
                function (item) { return item.row >= 5 && item.row <= 11; }
              )),function(row,index){return _c('div',{key:index,staticClass:"seats-row ",class:row.class},_vm._l((row.seats),function(seats,idx){return _c('div',{key:idx,staticClass:"seats-column",class:[
                  seats[0] &&
                    _vm.modifySeats.class.find(function (item) { return item.row === row.row; }) &&
                    _vm.modifySeats.class
                      .find(function (item) { return item.row === row.row; })
                      .seats.find(function (item) { return item.group === seats[0].group; })
                      .class,
                  {
                    'four-seats':
                      seats.length === 0 &&
                      [29, 34, 35].indexOf(row.row) !== -1
                  }
                ]},_vm._l((seats),function(seat,ix){return _c('div',{key:ix,staticClass:"seat",class:[
                    seat.seatAvailability === 0 ||
                    seat.nameHash ||
                    seat.disabled
                      ? 'unavailable'
                      : '',
                    seat.class,
                    seat.seatNumber === _vm.parentSelected ? 'selected' : '',
                    _vm.user.seat === seat.seatNumber ? 'active' : ''
                  ],on:{"click":function($event){return _vm.checkUpgradeSeat(seat, seats)}}},[_c('i'),_c('b'),_c('div',{staticClass:"label"},[_vm._v(_vm._s(seat.seatNumber))])])}),0)}),0)}),0)]),_c('info',{attrs:{"selected-column":_vm.parentSelectedColumn,"selected":_vm.parentSelected,"type":_vm.type,"info":_vm.infoPopup},on:{"selected":function($event){_vm.parentSelected = false},"closeInfo":function($event){_vm.infoPopup = false},"update":function($event){return _vm.$emit('update', $event)}}})],1)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',{staticClass:"tabnav-offset-item"},[_c('div')])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',{staticClass:"tabnav-offset-item"},[_c('div')])}]

export { render, staticRenderFns }